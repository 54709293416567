@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.bordered-file-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  border-radius: $main-border-radius;

  .base {
    $stroke-width: 1px;
    $dash-interval: 10px;
    $container-size: calc(100% - #{$stroke-width * 2});

    width: 100%;
    height: 100%;

    outline: $dash-interval dashed color($var-color-blue-light);
    outline-offset: -$dash-interval;

    transition: outline-color 0.1s ease-in-out;

    .input-container {
      position: relative;

      top: $stroke-width;
      left: $stroke-width;

      width: $container-size;
      height: $container-size;

      background-color: color($var-color-white);

      border-radius: $main-border-radius - $stroke-width;

      transition: background-color 0.1s ease-in-out;
    }
  }

  &.is-drag-accept .base {
    outline-color: color($var-color-blue-main);

    .input-container {
      background-color: color($var-color-blue-grey-light-2);
    }
  }

  &.is-drag-reject .base .input-container {
    //
  }
}
