@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.drop-file-input {
  width: 100%;
  height: 215px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 40px;

    .reject-icon {
      display: flex;
      align-items: center;
    }

    .reject-message {
      padding-bottom: 12px;

      color: color($var-color-orange);
    }

    .fake-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      padding: 0 16px;

      color: color($var-color-blue-light);
      font-weight: 700;

      font-size: 16px;

      background-color: color($var-color-white);

      border: 1px solid color($var-color-grey-light);
      border-radius: $main-border-radius;
    }

    &:hover {
      //

      .fake-button {
        color: color($var-color-blue-main);
      }
    }
  }
}
