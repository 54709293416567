@import "colors";
@import "react-toastify";

:root {
  @each $key, $value in $app-colors {
    --color-#{$key}: #{$value};
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  color: color($var-color-dark-soft);

  font-size: 14px;
  font-family: Arial, sans-serif;

  outline: none;
}

html,
body,
#root {
  height: 100%;
}

html {
  overflow-x: hidden;
}

body {
  background-color: color($var-color-grey-light-3);
}

button {
  width: 100%;

  cursor: pointer;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.container {
  width: 100%;
  height: inherit;
  padding: 0 50px
}

@media (width <= 1200px) {
  .container {
    padding: 0 20px
  }
}
