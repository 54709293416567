@import "@VIEW/styles/colors";

.email-form {
  //

  .form-wrapper {
    width: 240px;

    margin-bottom: 20px;

    .description {
      margin-bottom: 30px;
      padding: 0 16px;

      color: color($var-color-dark-soft);

      line-height: 20px;
      text-align: center;
    }

    .email-wrapper {
      margin-bottom: 5px;

      .error-message {
        display: flex;
        align-items: center;
        height: 20px;

        color: color($var-color-orange);
        font-size: 12px;
        line-height: 20px;
      }
    }

    .force-upload-wrapper {
      margin-bottom: 20px;
    }
  }
}
