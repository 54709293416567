@import "@VIEW/styles/colors";

.file-step {
  width: 100%;
  max-width: 625px;
  margin: auto;

  transform: translateY(-40px);

  .file-item-wrapper {
    margin-bottom: 10px;
  }

  .file-form {
    margin-bottom: 15px;
  }
}
