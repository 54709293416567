@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.file-item {
  position: relative;

  width: 100%;

  margin-bottom: 15px;
  padding: 16px;

  background-color: color($var-color-white);
  border-radius: $main-border-radius;

  .file-name-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .name,
    .percents {
      font-weight: 700;
      font-size: 16px;
    }

    .file-icon {
      margin-right: 10px;
    }

    .name {
      margin-right: 6px;
    }

    .percents {
      //
    }
  }

  .processing-wrapper {
    //

    .track {
      position: relative;

      width: 100%;
      height: 4px;
      overflow: hidden;

      background-color: color($var-color-blue-grey-light);
      border-radius: 2px;

      .progress {
        position: absolute;
        left: 0;

        height: 100%;

        background-color: color($var-color-blue-dark);
        border-radius: 2px;

        transition: width 0.5s ease-in-out;
      }
    }
  }
}
