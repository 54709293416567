@mixin custom-scrollbar($thumb-color: lightgrey, $track-color: transparent) {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius: 2px;
  }

  scrollbar-width: thin;
  scrollbar-color: $thumb-color $track-color;
}
