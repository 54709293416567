/* stylelint-disable selector-class-pattern */

@import "colors";
@import "react-toastify/dist/ReactToastify.css";

.Toastify__toast-container {
  //

  .Toastify__toast {
    border-radius: 8px;
  }

  .Toastify__progress-bar {
    background-color: color($var-color-blue-main);
  }

  .Toastify__close-button {
    width: unset;
  }
}
