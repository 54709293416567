@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.basic-input {
  display: flex;
  flex-direction: column;

  label {
    display: block;

    .input-label {
      font-size: 12px;
      line-height: 20px;
    }

    input[type="text"] {
      width: 100%;
      height: 40px;
      padding: 0 16px;

      font-weight: normal;
      font-size: 14px;

      background-color: color($var-color-white);

      border: 1px solid color($var-color-grey-light);
      border-radius: $main-border-radius;

      &::placeholder {
        color: color($var-color-grey);
        font-size: 14px;
      }

      &:focus {
        border-color: color($var-color-grey);
      }

      &:disabled {
        border-color: color($var-color-grey-light) !important;
      }
    }
  }

  &.error {
    //

    label input[type="text"] {
      border-color: color($var-color-orange);
    }
  }
}
