@import "@VIEW/styles/colors";

.report {
  margin-bottom: 30px;

  .title {
    margin-bottom: 10px;

    line-height: 20px;
    text-align: center;
  }

  .email {
    color: color($var-color-dark-soft);
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
  }
}
