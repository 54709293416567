@import "@VIEW/styles/colors";

.note {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  .text {
    color: color($var-color-grey);
    line-height: 20px;
  }
}
