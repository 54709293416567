@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.api-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 16px;

  text-align: center;

  .code {
    display: flex;
    align-items: center;
    height: 36px;
    margin: 0 8px;
    padding: 0 16px;

    color: color($var-color-blue-dark);
    font-size: 14px;

    background-color: color($var-color-blue-grey-light-2);
    border-radius: $main-border-radius;
  }
}
