@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";

.home {
  display: flex;
  width: 100%;
  height: 100%;

  .main {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;

    .content-wrapper {
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: center;

      .form-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 625px;
        height: 70%;

        .file-wrapper {
          width: 100%;

          margin-bottom: 35px;
        }
      }
    }
  }

  .ignore-list-wrapper {
    width: 490px;
    height: 100%;

    background-color: color($var-color-white);
    border-top-left-radius: $main-border-radius;
    border-bottom-left-radius: $main-border-radius;
    box-shadow: -12px 0 76px 0 #0000001C;

    @media (width <= 1024px) {
      width: 410px;
    }
  }
}
