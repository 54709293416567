.email-step {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;

  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 625px;
    height: 70%;

    .file-wrapper {
      width: 100%;

      margin-bottom: 35px;
    }
  }
}
