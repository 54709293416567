@import "@VIEW/styles/colors";

.file-confirm {
  width: 100%;
  height: 215px;
  margin-bottom: 15px;

  .content {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 40px;

    .icon {
      //
    }

    .title {
      padding-bottom: 15px;
    }

    .button-wrapper {
      width: 175px;
      height: 52px;

      .spinner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    .clear-button-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;

      .clear-button {
        padding: 5px;

        svg path {
          stroke: color($var-color-blue-dark);
        }

        &:hover {
          //

          svg path {
            stroke: color($var-color-blue-main);
          }
        }
      }
    }
  }
}
