@import "@VIEW/styles/colors";
@import "@VIEW/styles/design";
@import "@VIEW/styles/mixins";

.ignore-list {
  height: 100%;
  margin-right: 4px;

  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: auto;

  @include custom-scrollbar($thumb-color: lightgrey, $track-color: transparent);

  .table-wrapper {
    width: 100%;
    padding: 0 6px 0 10px;

    table-layout: auto;
    border-collapse: collapse;


    .description {
      padding-bottom: 16px;

      color: color($var-color-grey);

      text-align: center;
    }

    table {
      width: 100%;

      table-layout: auto;

      border-collapse: collapse;
      border-spacing: 0;

      .spacer {
        height: 8px;
      }

      thead {
        position: sticky;
        top: 10px;
        z-index: 10;

        &::before {
          position: absolute;
          bottom: 100%;

          width: 100%;
          height: 10px;

          background-color: color($var-color-white);

          content: "";
        }

        tr {
          //

          th {
            background-color: color($var-color-blue-grey-light-2);

            &:nth-child(1) {
              width: 48%;
            }

            &:nth-child(2) {
              width: auto;
            }

            &:nth-child(3) {
              width: 42px;
            }

            &:first-child {
              border-top-left-radius: $main-border-radius;
            }

            &:last-child {
              border-top-right-radius: $main-border-radius;
            }

            .header-cell {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              height: 40px;
              padding: 0 8px;

              .title {
                color: color($var-color-dark-soft);
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }

      tbody {
        //

        tr {
          //

          td {
            position: relative;

            padding: 16px 8px;

            background-color: color($var-color-grey-light-3);

            .column-cell {
              height: 100%;

              line-height: 20px;
            }

            .field-cell {
              color: color($var-color-blue-dark);
            }

            .delete-cell {
              position: absolute;
              top: 0;
              left: 0;

              width: 100%;
              height: 100%;
            }
          }

          &:last-child td {
            border-bottom-right-radius: $main-border-radius;
            border-bottom-left-radius: $main-border-radius;
          }
        }
      }
    }

    .spinner-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }

    .delete-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      svg path {
        stroke: color($var-color-blue-dark);
      }

      &:hover {
        svg path {
          stroke: color($var-color-blue-main);
        }
      }
    }
  }
}
